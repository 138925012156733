import React, { useEffect, useState } from 'react';

import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/profile.png'
import Edit from '../Assets/Edit.svg'
import img2 from '../Assets/profile2.png'
import img3 from '../Assets/Rectangle 22027.png'
import loc from '../Assets/system-uicons_location.png'
import date from '../Assets/date-svgrepo-com 5.png'
import pro from '../Assets/Ellipse 6.png'
import time from '../Assets/time-filled-svgrepo-com 1.png'
import Delete from '../Assets/Delete.svg'
import { apiDelete, apiGet, apiPost, apiPut, formatDate, formatDateonly, Get_Api } from '../ApiUrls';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import UpdateTurfOwner from '../Components/UpdateTurfOwner';
import UpdateUserModel from '../Components/UpdateUserModel';
import UpdateCoachingInstituteModel from '../Components/UpdateCoachingInstituteModel';
import DeleteGameModel from './Game/DeleteGameModel';
import CancelTurfBookingModel from '../Components/CancelTurfBookingModel';
import CancelprogramBookingModel from '../Components/CancelprogramBookingModel';

function Profile() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [selecteddate, setselecteddate] = useState('');

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const [IsUser, setIsUser] = useState(false);
  const [IsInstitute, setIsInstitute] = useState(false);
  const [IsTurfOwner, setIsTurfOwner] = useState(false);


  useEffect(() => {
    const userType = JSON.parse(localStorage.getItem("userType"));

    if (userType === "User") {
      setIsUser(true);
    }
    if (userType === "CoachingInstitute") {
      setIsInstitute(true);
    }
    if (userType === "TurfOwner") {
      setIsTurfOwner(true);
    }
  }, []);

  //=======================[User ]==================================


  const [Userdata, setUserdata] = useState();
  const [AllturfBooking, setAllturfBooking] = useState([]);
  const [AllcoachBooking, setAllcoachBooking] = useState([]);
  const [IsUploading, setIsUploading] = useState(false);
  const [Games, setGames] = useState([]);


  const GetUserprofile = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/user/GetUserprofile');
      setUserdata(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const GetAllturfBooking = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet(`api/user/GetAllMyturfBooking?date=${selecteddate}`);
      setAllturfBooking(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const GetAllcoachBooking = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/user/GetAllMyCoachBooking');
      setAllcoachBooking(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const GetAllGames = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/game/GetAllGamesofauser');
      setGames(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [CancelturfModel, setCancelturfModel] = useState(false);
  const [turfBookingId, setturfBookingId] = useState();
  const [refundMessage, setRefundMessage] = useState("");
  const [refundAmount, setrefundAmount] = useState();

  const getRefundMessage = (DateandTime, price) => {
    const bookingDateTime = new Date(DateandTime);
    const currentTime = new Date();
    console.log(DateandTime,bookingDateTime,currentTime)
    const timeDifference = (bookingDateTime - currentTime) / (1000 * 60 * 60) -5  ;
    let message = "";
    let refund = 0;

    if (timeDifference > 6) {
      message = "A 100% refund will be initiated for cancellations made more than 6 hours before the booked slot.";
      refund = price;
    } else if (timeDifference > 4) {
      message = "Only 70% of the amount will be refunded for cancellations made 4-6 hours before the booked slot.";
      refund = price * 0.7;
    } else if (timeDifference > 2) {
      message = "Only 50% of the amount will be refunded for cancellations made 2-4 hours before the booked slot.";
      refund = price * 0.5;
    } else {
      message = "No refund will be provided for cancellations made within 2 hours of the booked slot.";
      refund = 0;
    }

    setrefundAmount(refund);
    return message;
  };

  const OpenCancelBooking = (booking) => {
    setRefundMessage(getRefundMessage(booking?.DateandTime, booking?.price));
    setCancelturfModel(true);
    setturfBookingId(booking._id);
  };

  const CloseCancelBooking = () => {
    setCancelturfModel(false)
  }

  const [reason, setreason] = useState('');

  const CancelturfBooking = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }
      let data = {
        reason,
        refundAmount
      }
      setIsUploading(true);
      const response = await apiPost(`api/user/cancelTurfBooking/${turfBookingId}`, data);
      console.log(response?.data)
      setreason('')
      setIsUploading(false);
      toast.success(response?.message)
      CloseCancelBooking();
      GetAllcoachBooking();
    } catch (error) {
      toast.error(error?.response?.message)
      console.error('Error fetching data:', error);
    }
  }



  const [CancelprogramModel, setCancelprogramModel] = useState(false);
  const [programBookingId, setprogramBookingId] = useState();
  const [refundMessage1, setRefundMessage1] = useState("");

  const getRefundMessage1 = (DateandTime, price) => {
    const bookingDateTime = new Date(DateandTime);
    const currentTime = new Date();
    console.log(bookingDateTime,currentTime)
    const timeDifference = (bookingDateTime - currentTime) / (1000 * 60 * 60) -5;

    let message = "";
    let refund = 0;

    if (timeDifference > 6) {
      message = "A 100% refund will be initiated for cancellations made more than 6 hours before the booked slot.";
      refund = price;
    } else if (timeDifference > 4) {
      message = "Only 70% of the amount will be refunded for cancellations made 4-6 hours before the booked slot.";
      refund = price * 0.7;
    } else if (timeDifference > 2) {
      message = "Only 50% of the amount will be refunded for cancellations made 2-4 hours before the booked slot.";
      refund = price * 0.5;
    } else {
      message = "No refund will be provided for cancellations made within 2 hours of the booked slot.";
      refund = 0;
    }

    setrefundAmount(refund);
    return message
  };

  const OpenCancelprogramBooking = (booking) => {
    setRefundMessage1(getRefundMessage1(booking?.date, booking?.Total));
    setCancelprogramModel(true);
    setprogramBookingId(booking._id);
  };

  const CloseCancelprogramBooking = () => {
    setCancelprogramModel(false)
  }




  const CancelProgramBooking = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }
      let data = {
        reason,
        refundAmount
      }
      setIsUploading(true);
      const response = await apiPost(`api/user/cancelProgramBooking/${programBookingId}`, data);
      console.log(response?.data)
      setreason('')
      setIsUploading(false);
      toast.success(response?.message)
      CloseCancelprogramBooking();
      GetAllturfBooking();
    } catch (error) {
      toast.error(error?.response?.message)
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (IsUser) {
      GetUserprofile();
      GetAllturfBooking();
      GetAllcoachBooking();
      GetAllGames();
    }
  }, [IsUser]);

  useEffect(() => {
    if (IsUser) {
      GetAllturfBooking();
    }
  }, [selecteddate]);


  const GetScoreCard = (id) => {
    navigate(`/GetScoreCard/${id}`)
  }


  const [id, setid] = useState('');

  const [DeleteModel, setDeleteModel] = useState(false);

  const OpenDeleteModel = (id) => {
    setid(id)
    setDeleteModel(true)
  }

  const CloseDeleteModel = () => {
    setDeleteModel(false)
  }

  const DeleteGame = async () => {
    try {
      const response = await apiDelete(`api/game/deleteGame/${id}`)
      toast.success(response?.message);
      GetAllGames();
      CloseDeleteModel();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error)
    }
  }



  //=================[Turf Owner ]=================//


  const [turfonwer, setturfonwer] = useState();

  const [AllBookings, setAllBookings] = useState([]);

  const [AllGameofTurfowner, setAllGameofTurfowner] = useState([]);

  const [AllProgramBookingofTurfOwner, setAllProgramBookingofTurfOwner] = useState([]);

  const [AllSelfTurfBooking, setAllSelfTurfBooking] = useState([]);

  const GetTurfownerprofile = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/turf/GetTurfOwener');
      setturfonwer(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const GetTurfownerBookings = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/turf/GetAllBookingofatrufowner');
      setAllBookings(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const GetSelfTurfownerBookings = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/turf/GetAllMyturfBookingofaTurfOwner');
      setAllSelfTurfBooking(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const GetTurfownerprogramBookings = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/turf/GetAllMyProgramBookingofATurfOwner');
      setAllProgramBookingofTurfOwner(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const GetAllGamesTurfOwner = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/game/GetAllGamesofaturfOwner');
      setAllGameofTurfowner(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const TurfOwnerCancelProgramBooking = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }
      let data = {
        reason,
        refundAmount
      }
      setIsUploading(true);
      const response = await apiPost(`api/turf/TurfownercancelProgramBooking/${programBookingId}`, data);
      console.log(response?.data)
      setreason('')
      setIsUploading(false);
      toast.success(response?.message)
      CloseCancelprogramBooking();
      GetAllturfBooking();
    } catch (error) {
      toast.error(error?.response?.message)
      console.error('Error fetching data:', error);
    }
  }




  useEffect(() => {
    if (IsTurfOwner) {
      GetTurfownerprofile();
      GetTurfownerBookings();
      GetAllGamesTurfOwner();
      GetSelfTurfownerBookings();
      GetTurfownerprogramBookings();
    }
  }, [IsTurfOwner]);




  // ===================[Coach profile ]=================================


  const [Institute, setInstitute] = useState();

  const [AllInstituteCoachBooking, setAllInstituteCoachBooking] = useState([]);

  const [AllGamesofInstituteOwner, setAllGamesofInstituteOwner] = useState([]);

  const [AllTurfBookingOfAintitute, setAllTurfBookingOfAintitute] = useState([]);

  const [SelfProgramBooking, setSelfProgramBooking] = useState([]);

  const GetInstituteprofile = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/coach/GetCoachingInstitute');
      setInstitute(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const GetAllCoachBooking = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet(`api/coach/GetAllBooking`);
      setAllInstituteCoachBooking(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const GetAllSelfCoachBooking = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet(`api/coach/GetAllMyProgramBookingofAInstituteOwner`);
      setSelfProgramBooking(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const GetAllGamesInstituteOwner = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet('api/game/GetAllGamesofaInstituteOwner');
      setAllGamesofInstituteOwner(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const GetAllturfBookingofaintitute = async () => {
    try {
      setIsUploading(true);
      const response = await apiGet(`api/coach/GetAllMyturfBookingofaInstituteOwner?date=${selecteddate}`);
      setAllTurfBookingOfAintitute(response.data);
      setIsUploading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const InstituteOwnerCancelturfBooking = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }
      let data = {
        reason,
        refundAmount
      }
      setIsUploading(true);
      const response = await apiPost(`api/coach/InstitutecancelTurfBooking/${turfBookingId}`, data);
      console.log(response?.data)
      setreason('')
      setIsUploading(false);
      toast.success(response?.message)
      CloseCancelBooking();
      GetAllturfBookingofaintitute();
    } catch (error) {
      toast.error(error?.response?.message)
      console.error('Error fetching data:', error);
    }
  }




  useEffect(() => {
    if (IsInstitute) {
      GetInstituteprofile();
      GetAllCoachBooking();
      GetAllGamesInstituteOwner();
      GetAllturfBookingofaintitute();
      GetAllSelfCoachBooking();
    }
  }, [IsInstitute]);



  const Logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userType');
    localStorage.removeItem('userid');
    localStorage.removeItem('userToken');
    toast.success('Logged out successfully!');
    navigate('/')
  }

  const [dates, setDates] = useState([]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const dateArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const formattedDate = date.toISOString().split('T')[0];
      dateArray.push(formattedDate);
    }

    setDates(dateArray);
  }, []);

  const handleDateChange = (e) => {
    setselecteddate(e.target.value);
  };

  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [Password, setPassword] = useState('');

  // ======================= [Update turf owner ] ======================// 


  const [UpdateturfOwner, setUpdateturfOwner] = useState(false);

  const Openupdateturfowner = () => {
    setName(turfonwer?.Name)
    setEmail(turfonwer?.Email)
    setUpdateturfOwner(true)
  }
  const CloseUddateturfowner = () => {
    setUpdateturfOwner(false)
  }


  const UpdateTurfOwnerapi = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }

      let data = {
        Name: Name,
        Email: Email,
        Password: Password
      }
      setIsUploading(true);
      const response = await apiPut(`api/turf/UpdateTurfOwner`, data);
      console.log(response?.data)
      setName('')
      setEmail('')
      setPassword('')
      setIsUploading(false);
      toast.success(response?.message)
      CloseUddateturfowner();
      GetTurfownerprofile();
    } catch (error) {
      toast.error(error?.response?.message)
      console.error('Error fetching data:', error);
    }
  }



  // ==================[Update User ] ===========================



  const [Updateuser, setUpdateuser] = useState(false);

  const Openupdateuser = () => {
    setName(Userdata?.Name)
    setEmail(Userdata?.Email)
    setphoneNumber(Userdata?.phoneNumber)
    setUpdateuser(true)
  }
  const CloseUpdateuser = () => {
    setUpdateuser(false)
  }


  const Updateuserapi = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }

      let data = {
        Name: Name,
        Email: Email,
        phoneNumber: phoneNumber,
        Password: Password
      }
      setIsUploading(true);
      const response = await apiPut(`api/user/UpdateUser`, data);
      console.log(response?.data)
      setName('')
      setEmail('')
      setphoneNumber();
      setPassword('')
      setIsUploading(false);
      toast.success(response?.message)
      CloseUpdateuser();
      GetUserprofile();
    } catch (error) {
      toast.error(error?.response?.message)
      console.error('Error fetching data:', error);
    }
  }



  // ==================[Update Coaching institute ] ===========================



  const [Updateinstitute, setUpdateinstitute] = useState(false);

  const OpenUpdateinstitute = () => {
    setName(Institute?.Name)
    setEmail(Institute?.Email)
    setUpdateinstitute(true)
  }
  const CloseUpdateinstitute = () => {
    setUpdateinstitute(false)
  }




  const Updateinstituteapi = async () => {
    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        toast.error('Please Login First....');
        return
      }

      let data = {
        Name: Name,
        Email: Email,
        Password: Password,
      }
      setIsUploading(true);
      const response = await apiPut(`api/coach/UpdateCoachingInstitute`, data);
      console.log(response?.data)
      CloseUpdateinstitute();
      GetInstituteprofile();
      setName('')
      setEmail('')
      setPassword('')
      setIsUploading(false);
      toast.success(response?.message)

    } catch (error) {
      toast.error(error?.response?.message)
      console.error('Error fetching data:', error);
    }
  }


  const [city, setcity] = useState('');

  const localcity = localStorage.getItem("city");

  useEffect(() => {
    setcity(localcity || 'Indore')
  }, []);



  return (
    <>
      {IsUploading ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
          <ClipLoader color="#31ED31" size={200} />
        </div>
        :
        <>
          <Navbar city={city} setcity={setcity} />
          <div className='profile'>
            <div className='profile-left'>
              <div className="">
                <div className="newprofile-tabs">
                  <img src={img} />
                  <button
                    className={activeTab === 1 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(1)}
                  >
                    Profile
                  </button>
                  <button
                    className={activeTab === 2 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(2)}
                  >
                    My Turf Booking History
                  </button>
                  {!IsTurfOwner &&
                    <button
                      className={activeTab === 3 ? 'tab-btn active' : 'tab-btn'}
                      onClick={() => handleTabChange(3)}
                    >
                      My Institute Booking History
                    </button>
                  }
                  {IsInstitute &&
                    <button
                      className={activeTab === 10 ? 'tab-btn active' : 'tab-btn'}
                      onClick={() => handleTabChange(10)}
                    >
                      Self Institute Booking
                    </button>
                  }
                  {IsTurfOwner &&
                    <button
                      className={activeTab === 11 ? 'tab-btn active' : 'tab-btn'}
                      onClick={() => handleTabChange(11)}
                    >
                      Self Turf Booking
                    </button>
                  }
                  {IsTurfOwner &&
                    <button
                      className={activeTab === 12 ? 'tab-btn active' : 'tab-btn'}
                      onClick={() => handleTabChange(12)}
                    >
                      Turf Owner Institute Booking
                    </button>
                  }
                  <button
                    className={activeTab === 6 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(6)}
                  >
                    All Games
                  </button>
                  <button
                    className={activeTab === 9 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(9)}
                  >
                    Wish List
                  </button>
                  <button
                    className={activeTab === 4 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(4)}
                  >
                    Analytics
                  </button>
                  <button
                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(5)}
                  >
                    Availability
                  </button>
                  <button
                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(5)}
                  >
                    Sales
                  </button>
                  <button
                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(5)}
                  >
                    Review / Ratings
                  </button>
                  <button
                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(5)}
                  >
                    Wallet
                  </button>
                  <button
                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => handleTabChange(5)}
                  >
                    Earnings
                  </button>
                  <button
                    className={activeTab === 5 ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => Logout()}
                  >
                    Log Out
                  </button>
                </div>
                <br />
                <br />
              </div>
            </div>
            <div className='profile-right'>
              <div className="tab-content">
                {activeTab === 1 &&
                  <>
                    {IsUser &&
                      <div className='profile-main1'>
                        <div className='pro-1'>
                          <div className='pr-box1'>
                            <img src={img2} />
                            <div className='profile-user-name'>
                              <p>{Userdata?.Name}</p>
                            </div>
                          </div>
                          <div className='pr-box2'>
                            <p>Profile Completion</p>
                            <br />
                            <br />
                            <br />
                            <div className='profile-persent'>
                              <div className='profile-persent-div'>
                                <p>90%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className='pro-2'>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>My Account</h3>
                            <img onClick={() => { Openupdateuser() }} src={Edit} alt="Edit" />
                          </div>

                          <br />
                          <div className='profile-name-div'>
                            <p>Full Name</p>
                            <p>{Userdata?.Name}</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Email</p>
                            <p>{Userdata?.Email}</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Number</p>
                            <p>{Userdata?.phoneNumber}</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Password</p>
                            <p>**********</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Profile Type</p>
                            <p>Platinium</p>
                          </div>
                        </div>
                      </div>
                    }
                    {IsInstitute &&
                      <div className='profile-main1'>
                        <div className='pro-1'>
                          <div className='pr-box1'>
                            <img style={{ height: 300, width: 400 }} src={Institute?.profileimage} />
                            <div className='profile-user-name'>
                              <p>{Institute?.Name}</p>
                            </div>
                          </div>
                          <div className='pr-box2'>
                            <p>Profile Completion</p>
                            <br />
                            <br />
                            <br />
                            <div className='profile-persent'>
                              <div className='profile-persent-div'>
                                <p>90%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className='pro-2'>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>My Account</h3>
                            <img onClick={() => { OpenUpdateinstitute() }} src={Edit} alt="Edit" />
                          </div>

                          <br />
                          <div className='profile-name-div'>
                            <p>Full Name</p>
                            <p>{Institute?.Name}</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Email</p>
                            <p>{Institute?.Email}</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Password</p>
                            <p>**********</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Profile Type</p>
                            <p>{Institute?.Type}</p>
                          </div>
                        </div>
                      </div>
                    }
                    {IsTurfOwner &&
                      <div className='profile-main1'>
                        <div className='pro-1'>
                          <div className='pr-box1'>
                            <img src={img2} />
                            <div className='profile-user-name'>
                              <p>{turfonwer?.Name}</p>
                            </div>
                          </div>
                          <div className='pr-box2'>
                            <p>Profile Completion</p>
                            <br />
                            <br />
                            <br />
                            <div className='profile-persent'>
                              <div className='profile-persent-div'>
                                <p>90%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className='pro-2'>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3>My Account</h3>
                            <img onClick={() => { Openupdateturfowner() }} src={Edit} alt="Edit" />
                          </div>

                          <br />
                          <div className='profile-name-div'>
                            <p>Full Name</p>
                            <p>{turfonwer?.Name}</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Email</p>
                            <p>{turfonwer?.Email}</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Password</p>
                            <p>**********</p>
                          </div>
                          <div className='profile-name-div'>
                            <p>Profile Type</p>
                            <p>{turfonwer?.Type} Owner</p>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
                {activeTab === 2 &&
                  <>
                    {IsUser &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Turf Bookings ({AllturfBooking?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllturfBooking?.map((slot, index) => {
                            const bookingDateAndTime = new Date(slot?.DateandTime);
                            const currentDate = new Date();
                            const isPastBooking = bookingDateAndTime < currentDate;

                            return (
                              <div key={index} className='all-bookings'>
                                <img className='all-bookingsimg' src={slot?.turf?.Images[0] ? slot?.turf?.Images[0] : img3} />
                                <div className='booking-text'>
                                  <h3>Turf Name - {slot?.turf?.Name}</h3>
                                  <h4>Contact - {slot?.Turfowner?.Email}</h4>
                                  <p><img src={loc} />{slot?.turf?.Location}</p>
                                  <h4>Rs {(slot?.totalAmount || 0) + (slot?.convenienceFees || 0)}</h4>
                                  <div className='booking-timing'>
                                    <p><img src={date} />{formatDateonly(slot?.date)}</p>
                                    <p><img src={time} />{slot?.startTime} / {slot?.endTime}</p>
                                    <p><img src={pro} />{slot?.Turfowner?.Name}</p>
                                  </div>
                                  <div className='booking-timing'>
                                    <p><img src={time} />Booked Date - {formatDate(slot?.createdAt)}</p>
                                  </div>
                                  {slot?.reason &&
                                    <div className='booking-timing'>
                                      <p>reason - {slot?.reason}  {slot?.IsCanceledbyTurfOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                    </div>
                                  }
                                  <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                    <button
                                      onClick={() => { OpenCancelBooking(slot) }}
                                      className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                      disabled={isPastBooking || slot?.Cancelrequest && slot.Cancelrequest !== "Notcreated"}

                                    >
                                      {slot?.Cancelrequest === 'Notcreated' ? "Cancel Booking" : `Cancel ${slot?.Cancelrequest}`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        </div>
                      </div>
                    }
                    {IsInstitute &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Turf Bookings ({AllTurfBookingOfAintitute?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllTurfBookingOfAintitute?.map((slot, index) => {
                            const bookingDateAndTime = new Date(slot?.DateandTime);
                            const currentDate = new Date();
                            const isPastBooking = bookingDateAndTime < currentDate;

                            return (
                              <div key={index} className='all-bookings'>
                                <img className='all-bookingsimg' src={slot?.turf?.Images[0] ? slot?.turf?.Images[0] : img3} />
                                <div className='booking-text'>
                                  <h3>Turf Name - {slot?.turf?.Name}</h3>
                                  <h4>Contact - {slot?.Turfowner?.Email}</h4>
                                  <p><img src={loc} />{slot?.turf?.Location}</p>
                                  <h4>Rs {(slot?.totalAmount || 0) + (slot?.convenienceFees || 0)}</h4>
                                  <div className='booking-timing'>
                                    <p><img src={date} />{formatDateonly(slot?.date)}</p>
                                    <p><img src={time} />{slot?.startTime} / {slot?.endTime}</p>
                                    <p><img src={pro} />{slot?.Turfowner?.Name}</p>
                                  </div>
                                  <div className='booking-timing'>
                                    <p><img src={time} />Booked Date - {formatDate(slot?.createdAt)}</p>
                                  </div>
                                  {slot?.reason &&
                                    <div className='booking-timing'>
                                      <p>reason - {slot?.reason}  {slot?.IsCanceledbyTurfOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                    </div>
                                  }
                                  <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                    <button
                                      onClick={() => { OpenCancelBooking(slot) }}
                                      className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                      disabled={isPastBooking || slot?.Cancelrequest && slot.Cancelrequest !== "Notcreated"}

                                    >
                                      {slot?.Cancelrequest === 'Notcreated' ? "Cancel Booking" : `Cancel ${slot?.Cancelrequest}`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                    {IsTurfOwner &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>All Turf Bookings ({AllBookings?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllBookings?.map((slot, index) => {
                            const bookingDateAndTime = new Date(slot?.DateandTime);
                            const currentDate = new Date();
                            const isPastBooking = bookingDateAndTime < currentDate;

                            return (
                              <div key={index} className={`all-bookings ${isPastBooking ? 'past-booking' : ''}`}>
                                <img className='all-bookingsimg' src={slot?.turf?.Images[0] ? slot?.turf?.Images[0] : img3} />
                                <div className='booking-text'>
                                  <h3>{slot?.turf?.Name}</h3>
                                  <h4>Contact - {slot?.user?.Name || slot?.InstituteOwnerId?.Name || slot?.UserName} {slot?.user?.phoneNumber || slot?.InstituteOwnerId?.Email}</h4>
                                  <p><img src={loc} />{slot?.turf?.Location}</p>
                                  <h4>Rs {(slot?.totalAmount || 0) + (slot?.convenienceFees || 0)}</h4>
                                  <div className='booking-timing'>
                                    <p><img src={date} />{formatDateonly(slot?.date)}</p>
                                    <p><img src={time} />{slot?.startTime} / {slot?.endTime}</p>
                                    <p>
                                      <img src={pro} />
                                      {slot?.BookByOwner ? (
                                        <>
                                        Self - {slot?.UserName} {slot?.UserNumber}
                                        </>
                                      ) : slot?.BookByInstitute ? (
                                        <>
                                          Institute Owner - {slot?.InstituteOwnerId?.Name}({slot?.InstituteOwnerId?.Email})
                                        </>
                                      ) : (
                                        <>
                                          User - {slot?.user?.Name} ({slot?.user?.phoneNumber})
                                        </>
                                      )}
                                    </p>
                                  </div>
                                  {slot?.reason &&
                                    <div className='booking-timing'>
                                      <p>reason - {slot?.reason}  {slot?.IsCanceledbyTurfOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                    </div>
                                  }
                                  <div className='booking-timing'>
                                    <p><img src={time} />Booked Date - {formatDate(slot?.createdAt)}</p>
                                  </div>
                                  <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                    <button
                                      // onClick={() => { OpenCancelBooking(slot) }}
                                      className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                      disabled={isPastBooking || slot?.Cancelrequest && slot.Cancelrequest !== "Notcreated"}

                                    >
                                      {slot?.Cancelrequest === 'Notcreated' ? "Cancel Booking" : `Cancel ${slot?.Cancelrequest}`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        </div>
                      </div>
                    }
                  </>
                }

                {activeTab === 3 &&
                  <>
                    {IsUser &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Coach Bookings ({AllcoachBooking?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllcoachBooking?.map((slot, index) => {
                            const [day, month, year] = slot?.date?.split('-');
                            const bookingDate = new Date(`${year}-${month}-${day}`);
                            const currentDate = new Date();
                            const isPastBooking = bookingDate < currentDate;
                            return (
                              <div key={index} className={`all-bookings ${isPastBooking ? 'past-booking' : ''}`}>
                                <img
                                  className='all-bookingsimg'
                                  src={slot?.coach?.profileimage ? slot?.coach?.profileimage : img3}
                                  alt="Coach Profile"
                                />
                                <div className="booking-text">
                                  <h3>Institute - {slot?.Institute?.Name}</h3>
                                  <h4>Program - {slot?.Program?.ProgramName}</h4>
                                  <h4>Coach - {slot?.coach?.Name}</h4>
                                  <p><img src={loc} alt="Location icon" />{slot?.coach?.Location}</p>
                                  <h4>Rs {Number(slot?.Total || 0) + Number(slot?.ConvenienceFees || 0)}</h4>
                                  <div className="booking-timing">
                                    <p><img src={date} alt="Date icon" />{slot?.Program?.StartDate}</p>
                                    <p><img src={pro} alt="Profile icon" />{slot?.coach?.Name}</p>
                                  </div>
                                  <div className="booking-timing">
                                    <p><img src={time} alt="Time icon" />Booked Date - {formatDate(slot?.createdAt)}</p>
                                  </div>
                                  {slot?.reason &&
                                    <div className='booking-timing'>
                                      <p>reason - {slot?.reason}  {slot?.IsCanceledbyInstituteOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                    </div>
                                  }
                                  <div style={{ top: 30 }} className="booking-CRUD-btns">
                                    <button
                                      className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                      disabled={isPastBooking || slot?.Cancelrequest !== "Notcreated"}
                                      onClick={() => { OpenCancelprogramBooking(slot) }}
                                    >
                                      {slot?.Cancelrequest === "Notcreated" ? "Cancel Institute Booking" : "Cancel Request Pending"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        </div>
                      </div>
                    }
                    {IsInstitute &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Institute Bookings ({AllInstituteCoachBooking?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllInstituteCoachBooking?.map((slot, index) => {
                            const [day, month, year] = slot?.date?.split('-');
                            const bookingDate = new Date(`${year}-${month}-${day}`);
                            const currentDate = new Date();
                            const isPastBooking = bookingDate < currentDate;
                            console.log(bookingDate, slot?.date)
                            return (
                              <div key={index} className={`all-bookings ${isPastBooking ? 'past-booking' : ''}`}>
                                <img
                                  className='all-bookingsimg'
                                  src={slot?.coach?.profileimage ? slot?.coach?.profileimage : img3}
                                  alt="Coach Profile"
                                />
                                <div className="booking-text">
                                  <h3>Institute - {slot?.Institute?.Name}</h3>
                                  <h4>Program - {slot?.Program?.ProgramName} </h4>
                                  <h4>Total Slots -({slot?.slots})</h4>
                                  <h4>Coach - {slot?.coach?.Name}</h4>
                                  <p><img src={loc} alt="Location icon" />{slot?.coach?.Location}</p>
                                  <h4>Rs {Number(slot?.Total || 0) + Number(slot?.ConvenienceFees || 0)}</h4>
                                  <div className="booking-timing">
                                    <p><img src={date} alt="Date icon" />{slot?.Program?.StartDate}</p>
                                    <p><img src={pro} alt="Profile icon" />{!slot?.BookByOwner ? slot?.user?.Name : "Owner Booking"}</p>
                                  </div>
                                  <div className="booking-timing">
                                    <p><img src={time} alt="Time icon" />Booked Date - {formatDate(slot?.createdAt)}</p>
                                  </div>
                                  {slot?.reason &&
                                    <div className='booking-timing'>
                                      <p>reason - {slot?.reason}  {slot?.IsCanceledbyInstituteOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                    </div>
                                  }
                                  <div style={{ top: 30 }} className="booking-CRUD-btns">
                                    <button
                                      className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                      disabled={isPastBooking || slot?.Cancelrequest !== "Notcreated"}
                                      onClick={() => { OpenCancelprogramBooking(slot) }}
                                    >
                                      {slot?.Cancelrequest === "Notcreated" ? "Cancel Institute Booking" : "Cancel Request Pending"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                  </>
                }
                {activeTab === 10 &&
                  <div>
                    <div className='mybooking-heading'>
                      <h3>Self Institute Bookings ({SelfProgramBooking?.length || 0})</h3>
                      <select value={selecteddate} onChange={handleDateChange}>
                        <option>Select date</option>
                        {dates.map((date, index) => (
                          <option key={index} value={date}>{date}</option>
                        ))}
                      </select>
                    </div>
                    <br />
                    <div className='all-bookings-div'>
                      {SelfProgramBooking?.map((slot, index) => {
                        const [day, month, year] = slot?.date?.split('-');
                        const bookingDate = new Date(`${year}-${month}-${day}`);
                        const currentDate = new Date();
                        const isPastBooking = bookingDate < currentDate;
                        console.log(bookingDate, slot?.date)
                        return (
                          <div key={index} className={`all-bookings ${isPastBooking ? 'past-booking' : ''}`}>
                            <img
                              className='all-bookingsimg'
                              src={slot?.coach?.profileimage ? slot?.coach?.profileimage : img3}
                              alt="Coach Profile"
                            />
                            <div className="booking-text">
                              <h3>Institute - {slot?.Institute?.Name}</h3>
                              <h4>Program - {slot?.Program?.ProgramName}</h4>
                              <h4>Total Slots -({slot?.slots})</h4>
                              <h4>Coach - {slot?.coach?.Name}</h4>
                              <p><img src={loc} alt="Location icon" />{slot?.coach?.Location}</p>
                              <h4>Rs {Number(slot?.Total || 0) + Number(slot?.ConvenienceFees || 0)}</h4>
                              <div className="booking-timing">
                                <p><img src={date} alt="Date icon" />{slot?.Program?.StartDate}</p>
                                <p><img src={pro} alt="Profile icon" />{!slot?.BookByOwner ? slot?.user?.Name : "Owner Booking"}</p>
                              </div>
                              <div className="booking-timing">
                                <p><img src={time} alt="Time icon" />Booked Date - {formatDate(slot?.createdAt)}</p>
                              </div>
                              {slot?.reason &&
                                <div className='booking-timing'>
                                  <p>reason - {slot?.reason}  {slot?.IsCanceledbyInstituteOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                </div>
                              }
                              <div style={{ top: 30 }} className="booking-CRUD-btns">
                                <button
                                  className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                  disabled={isPastBooking || slot?.Cancelrequest !== "Notcreated"}
                                // onClick={() => { OpenCancelprogramBooking(slot) }}
                                >
                                  {slot?.Cancelrequest === "Notcreated" ? "Cancel Institute Booking" : "Cancel Request Pending"}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
                {activeTab === 6 &&
                  <>
                    {IsUser &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Games ({Games?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {Games?.map((game, index) => (
                            <div key={index} className='all-bookings'>
                              <img className='all-bookingsimg' src={img3} alt="Game thumbnail" />
                              <div className='booking-text'>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                  <h3>Game Name - {game?.GameName}</h3>
                                  <img style={{ width: 30, height: 30 }} onClick={() => { OpenDeleteModel(game?._id) }} src={Delete} alt="Delete" />
                                </div>
                                <p>Team Name - {game?.TeamName?.TeamAName} vs {game?.TeamName?.TeamBName}</p>
                                <p><img src={loc} alt="Location icon" /> {game?.VannueName}</p>

                                <h4>Total Players in Each team: {game?.NumberofPlayer} (Total Reserve Players: {game?.ReservePlayer})</h4>
                                <h4>Total Overs: {game?.OversMatch} Overs</h4>
                                <div className='booking-timing'>
                                  <p><img src={date} alt="Date icon" /> {new Date(game?.MatchDate).toLocaleDateString()}</p>
                                </div>
                                <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                  <button onClick={() => { GetScoreCard(game?._id) }} className='delete-btn'>View Score Card</button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                    {IsTurfOwner &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Games ({AllGameofTurfowner?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllGameofTurfowner?.map((game, index) => (
                            <div key={index} className='all-bookings'>
                              <img className='all-bookingsimg' src={img3} alt="Game thumbnail" />
                              <div className='booking-text'>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                  <h3>Game Name - {game?.GameName}</h3>
                                  <img style={{ width: 30, height: 30 }} onClick={() => { OpenDeleteModel(game?._id) }} src={Delete} alt="Delete" />
                                </div>
                                <p>Team Name - {game?.TeamName?.TeamAName} vs {game?.TeamName?.TeamBName}</p>
                                <p><img src={loc} alt="Location icon" /> {game?.VannueName}</p>

                                <h4>Total Players in Each team: {game?.NumberofPlayer} (Total Reserve Players: {game?.ReservePlayer})</h4>
                                <h4>Total Overs: {game?.OversMatch} Overs</h4>
                                <div className='booking-timing'>
                                  <p><img src={date} alt="Date icon" /> {new Date(game?.MatchDate).toLocaleDateString()}</p>
                                </div>
                                <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                  <button onClick={() => { GetScoreCard(game?._id) }} className='delete-btn'>View Score Card</button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                    {IsInstitute &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Games ({AllGamesofInstituteOwner?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllGamesofInstituteOwner?.map((game, index) => (
                            <div key={index} className='all-bookings'>
                              <img className='all-bookingsimg' src={img3} alt="Game thumbnail" />
                              <div className='booking-text'>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                  <h3>Game Name - {game?.GameName}</h3>
                                  <img style={{ width: 30, height: 30 }} onClick={() => { OpenDeleteModel(game?._id) }} src={Delete} alt="Delete" />
                                </div>
                                <p>Team Name - {game?.TeamName?.TeamAName} vs {game?.TeamName?.TeamBName}</p>
                                <p><img src={loc} alt="Location icon" /> {game?.VannueName}</p>

                                <h4>Total Players in Each team: {game?.NumberofPlayer} (Total Reserve Players: {game?.ReservePlayer})</h4>
                                <h4>Total Overs: {game?.OversMatch} Overs</h4>
                                <div className='booking-timing'>
                                  <p><img src={date} alt="Date icon" /> {new Date(game?.MatchDate).toLocaleDateString()}</p>
                                </div>
                                <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                  <button onClick={() => { GetScoreCard(game?._id) }} className='delete-btn'>View Score Card</button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  </>
                }
                {activeTab === 11 &&
                  <>
                    {IsTurfOwner &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>All Self Turf Bookings ({AllSelfTurfBooking?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllSelfTurfBooking?.map((slot, index) => {
                            const bookingDateAndTime = new Date(slot?.DateandTime);
                            const currentDate = new Date();
                            const isPastBooking = bookingDateAndTime < currentDate;

                            return (
                              <div key={index} className='all-bookings'>
                                <img className='all-bookingsimg' src={slot?.turf?.Images[0] ? slot?.turf?.Images[0] : img3} />
                                <div className='booking-text'>
                                  <h3>Turf Name - {slot?.turf?.Name}</h3>
                                  <h4>Contact - Self({slot?.UserName} {slot?.UserNumber})</h4>
                                  <p><img src={loc} />{slot?.turf?.Location}</p>
                                  <h4>Rs {(slot?.totalAmount || 0) + (slot?.convenienceFees || 0)}</h4>
                                  <div className='booking-timing'>
                                    <p><img src={date} />{formatDateonly(slot?.date)}</p>
                                    <p><img src={time} />{slot?.startTime} / {slot?.endTime}</p>
                                    <p><img src={pro} />Self- {slot?.UserName} {slot?.UserNumber}</p>
                                  </div>
                                  <div className='booking-timing'>
                                    <p><img src={time} />Booked Date - {formatDate(slot?.createdAt)}</p>
                                  </div>
                                  {slot?.reason &&
                                    <div className='booking-timing'>
                                      <p>reason - {slot?.reason}  {slot?.IsCanceledbyTurfOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                    </div>
                                  }
                                  <div style={{ top: 30 }} className='booking-CRUD-btns'>
                                    <button
                                      // onClick={() => { OpenCancelBooking(slot) }}
                                      className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                      disabled={isPastBooking || slot?.Cancelrequest && slot.Cancelrequest !== "Notcreated"}

                                    >
                                      {slot?.Cancelrequest === 'Notcreated' ? "Cancel Booking" : `Cancel ${slot?.Cancelrequest}`}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                  </>
                }
                {activeTab === 12 &&
                  <>
                    {IsTurfOwner &&
                      <div>
                        <div className='mybooking-heading'>
                          <h3>My Institute Bookings ({AllProgramBookingofTurfOwner?.length || 0})</h3>
                          <select value={selecteddate} onChange={handleDateChange}>
                            <option>Select date</option>
                            {dates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div className='all-bookings-div'>
                          {AllProgramBookingofTurfOwner?.map((slot, index) => {
                            const [day, month, year] = slot?.date?.split('-');
                            const bookingDate = new Date(`${year}-${month}-${day}`);
                            const currentDate = new Date();
                            const isPastBooking = bookingDate < currentDate;
                            console.log(bookingDate, slot?.date)
                            return (
                              <div key={index} className={`all-bookings ${isPastBooking ? 'past-booking' : ''}`}>
                                <img
                                  className='all-bookingsimg'
                                  src={slot?.coach?.profileimage ? slot?.coach?.profileimage : img3}
                                  alt="Coach Profile"
                                />
                                <div className="booking-text">
                                  <h3>Institute - {slot?.Institute?.Name}</h3>
                                  <h4>Program - {slot?.Program?.ProgramName}</h4>
                                  <h4>Total Slots -({slot?.slots})</h4>
                                  <h4>Coach - {slot?.coach?.Name}</h4>
                                  <p><img src={loc} alt="Location icon" />{slot?.coach?.Location}</p>
                                  <h4>Rs {Number(slot?.Total || 0) + Number(slot?.ConvenienceFees || 0)}</h4>
                                  <div className="booking-timing">
                                    <p><img src={date} alt="Date icon" />{slot?.Program?.StartDate}</p>
                                    <p><img src={pro} alt="Profile icon" />{slot?.BookByTurfOwner && slot?.TurfOwnerId?.Name || "Turf Owner Booking"}</p>
                                  </div>
                                  <div className="booking-timing">
                                    <p><img src={time} alt="Time icon" />Booked Date - {formatDate(slot?.createdAt)}</p>
                                  </div>
                                  {slot?.reason &&
                                    <div className='booking-timing'>
                                      <p>reason - {slot?.reason}  {slot?.IsCanceledbyInstituteOwner ? "( Canceled By Turf Owner )" : "( Canceled By User )"}</p>
                                    </div>
                                  }
                                  <div style={{ top: 30 }} className="booking-CRUD-btns">
                                    <button
                                      className={`delete-btn ${isPastBooking ? 'disabled' : ''}`}
                                      disabled={isPastBooking || slot?.Cancelrequest && slot.Cancelrequest !== "Notcreated"}
                                      onClick={() => { OpenCancelprogramBooking(slot) }}
                                    >
                                      {slot?.Cancelrequest === "Notcreated" ? "Cancel Institute Booking" : "Cancel Request Pending"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          <UpdateTurfOwner
            show={UpdateturfOwner}
            onClose={CloseUddateturfowner}
            Name={Name}
            Email={Email}
            setName={setName}
            setEmail={setEmail}
            Password={Password}
            setPassword={setPassword}
            UpdateTurfOwnerapi={UpdateTurfOwnerapi}
          />
          <UpdateUserModel
            show={Updateuser}
            onClose={CloseUpdateuser}
            Name={Name}
            Email={Email}
            setName={setName}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setphoneNumber={setphoneNumber}
            Password={Password}
            setPassword={setPassword}
            Updateuserapi={Updateuserapi}
          />
          <UpdateCoachingInstituteModel
            show={Updateinstitute}
            onClose={CloseUpdateinstitute}
            Name={Name}
            Email={Email}
            setName={setName}
            setEmail={setEmail}
            Password={Password}
            setPassword={setPassword}
            Updateinstituteapi={Updateinstituteapi}
          />
          <DeleteGameModel
            show={DeleteModel}
            Close={CloseDeleteModel}
            DeleteGame={DeleteGame}
          />
          <CancelTurfBookingModel
            show={CancelturfModel}
            Close={CloseCancelBooking}
            reason={reason}
            setreason={setreason}
            refundMessage={refundMessage}
            CancelturfBooking={IsInstitute ? InstituteOwnerCancelturfBooking : CancelturfBooking}
          />
          <CancelprogramBookingModel
            show={CancelprogramModel}
            Close={CloseCancelprogramBooking}
            reason={reason}
            setreason={setreason}
            refundMessage={refundMessage1}
            CancelturfBooking={IsTurfOwner ? TurfOwnerCancelProgramBooking : CancelProgramBooking}
          />
          <Footer />
        </>
      }
    </>
  )
}

export default Profile