import React from 'react';

const AddUserdetails = ({ show, onClose, UserName, UserNumber, setUserName, setUserNumber, BookSlots }) => {
    if (!show) return null;


    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <h2>Add User Details</h2>
                <br />
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Name</label>
                        <input
                            placeholder='Name'
                            value={UserName}
                            onChange={(e) => { setUserName(e.target.value) }}
                        />
                    </div>
                </div>
                <br />
                <div className='addtruf-ips'>
                    <div className='addtruf-ip'>
                        <label>Phone Number</label>
                        <input
                            placeholder='Phone Number'
                            value={UserNumber}
                            onChange={(e) => { setUserNumber(e.target.value) }}
                        />
                    </div>
                </div>
                <br />
                <button onClick={() => { BookSlots() }}>BookSlots</button>
                <button onClick={() => { onClose() }}>Close</button>
            </div>
        </div>
    );
};

export default AddUserdetails;
